<template>
    <div></div>
</template>

<script>
import { doRequest } from '@/utils/request'

export default {
    data() {
        return {}
    },
    mounted() {
        let id = this.$route.query['productId']

        doRequest('/recharge/createOrder', {
            product_id: parseInt(id, 0),
        }, this.$route.query).then(res => {
            let url = res.pay_url
            var link = document.createElement('a');
            link.href=url;
            link.rel = 'external'
            link.click();
        })
    }
}
</script>